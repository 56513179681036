<template>
  <div class="container p-3">

    <PrimePreloader v-if="loading"/>

    <div v-else>
      <h5 class="m-3">Список курсов (академ задолженность)</h5>
      <DataTable :value="academicDebtService.courses" :paginator="true" :rows="50"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[50,100,150]"
                 currentPageReportTemplate="Показано {last} из {totalRecords}"
                 stripedRows responsiveLayout="scroll">
        <Column field="name" header="Название курса"></Column>
        <Column field="year" header="Год"></Column>
        <Column field="created_at" header="Дата создания"></Column>
        <Column header="Преподаватель">
          <template #body="{data}">
            {{data.lastname}} {{data.firstname}}
          </template>
        </Column>
        <Column v-if="!isPractice" header="Создание курса в Moodle">
          <template #body="{data}">
            <div v-if="data.mdl_course_id">
              <a :href="`https://moodle.uib.kz/course/view.php?id=${data.mdl_course_id}`" target="_blank">
                Перейти к курсу
              </a>
            </div>
            <div v-else>
              <Button label="Создать курс в Moodle" @click="postMdlSummerCourse(data.id, data.is_practice)"
                      :disabled="btnCreateMdlCourseStatus"/>
            </div>
          </template>
        </Column>
        <Column v-if="!isPractice" header="Объединение">
          <template #body="{data}">
            <div v-if="data.mdl_course_id&&(academicDebtService.courses.length>1)" class="form-check">
              <input class="form-check-input" type="checkbox"
                     :value="data.id"
                     v-model="checkedCourses">
            </div>
          </template>
        </Column>
      </DataTable>


      <div v-if="(academicDebtService.courses.length>1)&&(checkedCourses.length>1)" class="text-center my-4">
        <Button icon="pi pi-link" label="Объединить"
                :loading="btnUniteCoursesLoading"
                @click="uniteCourses"/>
      </div>


    </div>

  </div>
</template>

<script>
  import {mapActions, mapState} from 'vuex'

  export default {
    name: "AcademicDebtCourses",
    data() {
      return {
        loading: true,
        discipline_id: +this.$route.query.discipline_id || 0,
        is_oldmoodle: +this.$route.query.is_oldmoodle || 0,
        btnCreateMdlCourseStatus: false,
        checkedCourses: [],
        btnUniteCoursesLoading: false,
      }
    },
    computed: {
      ...mapState('academicDebtService', ['academicDebtService']),
      isPractice() {
        return this.academicDebtService.courses.length && (+this.academicDebtService.courses[0].is_practice) === 1
      },
    },
    methods: {
      ...mapActions('academicDebtService', ['GET_SUMMER_COURSES', 'POST_MDL_SUMMER_COURSE', 'UNITE_COURSES']),
      async postMdlSummerCourse(summer_course, is_practice) {
        this.btnCreateMdlCourseStatus = true
        await this.POST_MDL_SUMMER_COURSE({summer_course, is_oldmoodle: this.is_oldmoodle, is_practice})
        await this.getSummerCourses()
        this.btnCreateMdlCourseStatus = false
      },
      async getSummerCourses() {
        await this.GET_SUMMER_COURSES({discipline_id: this.discipline_id, is_oldmoodle: this.is_oldmoodle})
      },
      async uniteCourses() {
        this.btnUniteCoursesLoading = true

        const res = await this.UNITE_COURSES(this.checkedCourses)
        if (res) {
          await this.getSummerCourses()
          this.$message({title: 'Успешно объединено'})
        }
        this.btnUniteCoursesLoading = false
      },
    },
    async mounted() {
      await this.getSummerCourses()
      this.loading = false
    }
  }
</script>

<style scoped>

</style>
